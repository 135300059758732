import type { ListReturnValue } from "@aws-amplify/data-schema-types";
import { parseResponse } from "./parseResponse.js";

export async function* toIterable<T>(
  executor: (token?: string) => ListReturnValue<T>,
): AsyncIterable<T> {
  let response = await executor();
  for (const item of parseResponse(response)) {
    yield item;
  }
  while (response.nextToken) {
    response = await executor(response.nextToken);
    for (const item of parseResponse(response)) {
      yield item;
    }
  }
}
