<script lang="ts">
    import { ModelName, ReteynSchema, organisationIdField } from "../schema";
    import { parseTruthyResponse } from "../dao";
    import { createEventDispatcher } from "svelte";
    import { V6Client } from "@aws-amplify/api-graphql";
    import { LoadingScreen } from "../reteyn-components";
    import type { ModelIntrospectionSchema } from "@aws-amplify/core/internals/utils";
    import List from "./List.svelte";
    import type { Field } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import AmplifyForm from "./AmplifyForm.svelte";
    import EditorCard from "./EditorCard.svelte";
    import FormField from "./FormField.svelte";
    import { isEditableField } from "./isEditableField";
    import Tabs from "./Tabs.svelte";
    import TabItem from "./TabItem.svelte";
    import { ModelType } from "./ModelType";
    import ModelItem from "./ModelItem.svelte";
    import { sortItems } from "../dao";
    import { formatTitle } from "./formatTitle";
    import {AuthorisationResolver} from "../organisation/index.js";
    export let model: ModelName;
    export let id: string;
    export let modelSchema: ModelIntrospectionSchema;
    export let authResolver: AuthorisationResolver;

    export const dispatch = createEventDispatcher<{
        select: { item: { id: string }; model: ModelName };
        create: { model: ModelName; options: object };
        tab: number;
    }>();

    export let tabIndex: number;
    export let client: V6Client<ReteynSchema>;
    let dataPromise: Promise<ModelType>;
    let selectionSet: string[];
    let parentFields: Field[];

    $: parentFields = Object.values(modelSchema.models[model].fields).filter(
        (f) => f.association?.connectionType === "BELONGS_TO",
    );
    $: selectionSet = Object.values(modelSchema.models[model].fields)
        .filter(
            (f) => f.association?.connectionType || typeof f.type === "string" || f.type.enum,
        )
        .map((f) => (f.type.model ? f.name + ".*" : f.name));
    $: dataPromise = loadData(selectionSet, model, id);

    async function loadData(
        selectionSet: string[],
        model: ModelName,
        id: string,
    ): Promise<ModelType> {
        const idField =
            modelSchema.models[model].primaryKeyInfo.primaryKeyFieldName;
        return parseTruthyResponse(
            client.models[model].get(
                { [idField]: id },
                {
                    selectionSet,
                },
            ),
        );
    }

    async function save(input: Partial<ModelType>): Promise<any> {
        console.log("Saving", input);
        const idField =
            modelSchema.models[model].primaryKeyInfo.primaryKeyFieldName;
        return  parseTruthyResponse(
            client.models[model].update({ ...input, [idField]: id })
        );
    }

    async function deleteListItem(
        modelName: ModelName,
        item: ModelType,
    ): Promise<void> {
        await parseTruthyResponse(client.models[modelName].delete(item));
        dataPromise = loadData(selectionSet, model, id);
    }
</script>

{#if dataPromise}
    {#await dataPromise}
        <LoadingScreen></LoadingScreen>
    {:then data}
        {@const organisationId = data[organisationIdField]}
        {@const canEdit = authResolver.canEdit({modelName: model, organisationId})}
        {@const fields = Object.values(modelSchema.models[model].fields)}
        {@const editableFields = fields.filter((f) => isEditableField(f))}
        {@const objectFields = fields.filter(
            (f) => f.association?.connectionType === "HAS_ONE",
        )}
        {@const arrayFields = fields.filter(
            (f) => f.association?.connectionType === "HAS_MANY",
        )}
        {@const excludedTypes = [model]}
        <EditorCard title={formatTitle(modelSchema.models[model].name)}>
            {#each parentFields as parentField}
                {#if data[parentField.name]}
                    {@const object = data[parentField.name]}
                    {@const modelName = parentField.type.model}
                    <FormField title={formatTitle(parentField.name)}>
                        <ModelItem
                            {client}
                            schema={modelSchema}
                            {modelName}
                            {object}
                            {excludedTypes}
                            on:click={() =>
                                dispatch("select", {
                                    item: object,
                                    model: modelName,
                                })}
                        ></ModelItem>
                    </FormField>
                {/if}
            {/each}
            {#if editableFields.length || objectFields.length}
                <AmplifyForm
                    {client}
                    {data}
                    {model}
                    {modelSchema}
                    on:update={(e) => canEdit ? save({ [e.detail.field.name]: e.detail.value }) : undefined}
                ></AmplifyForm>
                {#each objectFields as childField}
                    {@const object = data[childField.name]}
                    {@const modelName = childField.type.model}
                    {#if object}
                        <FormField title={formatTitle(childField.name)}>
                            <ModelItem
                                {client}
                                schema={modelSchema}
                                {modelName}
                                {excludedTypes}
                                {object}
                                on:click={() =>
                                    dispatch("select", {
                                        model: modelName,
                                        item: object,
                                    })}
                            ></ModelItem>
                        </FormField>
                    {/if}
                {/each}
            {/if}

            <Tabs
                on:tab={(event) => {
                    tabIndex = event.detail;
                    dispatch("tab", tabIndex);
                }}
                selected={tabIndex}
            >
                {#each arrayFields as childField}
                    {@const items = sortItems(data[childField.name] || [])}
                    {@const model = childField.type.model}
                    <TabItem title={formatTitle(childField.name)}>
                        {#if canEdit}
                            <div class="card-actions justify-end">
                                <button
                                    class="btn btn-primary"
                                    on:click={() =>
                                        dispatch("create", {
                                            model: childField.type.model,
                                            options: Object.fromEntries(
                                                childField.association.associatedWith.map(
                                                    (a) => [a, id],
                                                ),
                                            ),
                                        })}>Create {formatTitle(childField.type.model)}</button
                                >
                            </div>
                        {/if}
                        <List
                            {client}
                            {model}
                            {items}
                            {excludedTypes}
                            {modelSchema}
                            on:select={(event) =>
                                dispatch("select", {
                                    model,
                                    item: event.detail,
                                })}
                            on:remove={(event) =>
                                deleteListItem(model, event.detail)}
                            allowRemove={(_item) => canEdit}
                        ></List>
                    </TabItem>
                {/each}
            </Tabs>
        </EditorCard>
    {/await}
{/if}
