<script lang="ts">
    import type { AssociationBelongsTo, Field, ModelIntrospectionSchema } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import { ModelName, organisationIdField, type ReteynSchema } from "../schema";
    import AmplifyForm from "./AmplifyForm.svelte";
    import EditorCard from "./EditorCard.svelte";
    import FormField from "./FormField.svelte";
    import RemoveButton from "./RemoveButton.svelte";
    import Tabs from "./Tabs.svelte";
    import TabItem from "./TabItem.svelte";
    import List from "./List.svelte";
    import { InputType, parseTruthyResponse } from "../dao";
    import { createEventDispatcher } from "svelte";
    import { ModelType } from "./ModelType";
    import ModelItem from "./ModelItem.svelte";
    import { type V6Client } from "@aws-amplify/api-graphql";
    import {formatTitle} from "./formatTitle";
    import { listItems } from "./listItems";
    export const dispatch = createEventDispatcher<{submit: ModelType}>()
    export let model: ModelName;
    export let modelSchema: ModelIntrospectionSchema;
    export let params: Partial<ModelType>;
    export let client: V6Client<ReteynSchema>;
    let form: AmplifyForm;
    let data: Partial<ModelType> = {};
    let valid: boolean;
    let organisationId: string | undefined;

    let parentFields: Field[];
    $: organisationId = params[organisationIdField] as string;
    $: parentFields = Object.values(modelSchema.models[model].fields)
    .filter(f => f.association?.connectionType === "BELONGS_TO")
    .filter(f => !(f.association as AssociationBelongsTo).targetNames.every(key => Object.keys(params).includes(key)))

    $: valid = parentFields.every(f => data[f.name as keyof ModelType]) && form?.getForm()?.checkValidity()

    async function save() {
        const value = {...params, ...data} as ModelType;
        dispatch("submit", await parseTruthyResponse(client.models[model as ModelName.Topic].create(value as InputType<ModelName.Topic>)))
    }

    function onSubmit(input: Partial<ModelType> ) {
        data = {...data, ...input}
    }

</script>

<style>
    .select-list {
        max-height: 500px;
    }
</style>

<EditorCard title={"Create " + formatTitle(modelSchema.models[model].name)}>
    {@const excludedFields = [model]}
    {#each parentFields as parentField}
        {@const modelName = parentField.type.model}
        <FormField title={formatTitle(parentField.name)}>
            {@const object = data[parentField.name]}
            {#if object}
                <ModelItem {client} {object} schema={modelSchema} {modelName} {excludedFields}>
                    <RemoveButton on:click={() => data[parentField.name] = undefined}></RemoveButton>
                </ModelItem>
            {:else}
                {@const existingItemsPromise = listItems(client, {modelName, modelIntrospection: modelSchema, organisationId})}
                {#await existingItemsPromise}
                    <span class="loading loading-ring loading-lg"></span>
                {:then items}
                    <Tabs>
                        <TabItem title="Select">
                            <div class="select-list overflow-y-scroll">
                                <List {client} {items} {excludedFields} model={modelName} {modelSchema} on:select={event => data[parentField.name] = event.detail}></List>
                            </div>
                        </TabItem>
                        <TabItem title="Create">
                            <svelte:self {client} model={modelName} {modelSchema} params={modelSchema.models[modelName].fields[organisationIdField] ? {organisationId}: {}} on:submit={event => data[parentField.name] = event.detail}/>
                        </TabItem>
                    </Tabs>
                {/await}

            {/if}
        </FormField>
    {/each}
    <AmplifyForm bind:this={form} data={params} {model} {modelSchema} {client} on:submit={(e) => onSubmit(e.detail)}></AmplifyForm>
    <div class="card-actions justify-end">
        <button class="btn btn-primary" disabled={!valid} on:click={() => save()}>Create</button>
        <slot/>
    </div>
</EditorCard>