import { Results } from "./Results";
import flattenDeep from "lodash/flattenDeep";
import sortBy from "lodash/sortBy";
import { unparse } from "papaparse";
import { ResultEntry } from "./ResultEntry";
import FileSaver from "file-saver";

export class ResultDownloader {
  toEntries(results: Results): ResultEntry[] {
    console.log(results);
    return flattenDeep(
      results.topics.map((topic) =>
        topic.questions.map((question) =>
          question.answers.map((answer) =>
            answer.submissions.map((submission) => ({
              createdAt: submission.createdAt,
              firstName: submission.test.contact.firstName,
              lastName: submission.test.contact.lastName,
              email: submission.test.contact.email,
              topic: topic.text,
              topicIndex: topic.index,
              question: question.text,
              questionIndex: question.index,
              answer: answer.text,
              answerIndex: answer.index,
              correct: answer.correct,
            })),
          ),
        ),
      ),
    );
  }

  download(results: Results) {
    const entries = sortBy(this.toEntries(results), "createdAt");
    const resultsString = unparse(entries);
    const resultsBlob = new Blob([resultsString], {
      type: "text/plain;charset=utf-8",
    });
    const dateString = new Date().toISOString().substring(0, 10);
    const downloadFilename =
      results.name.replace(" ", "_") + "_" + dateString + ".csv";
    FileSaver.saveAs(resultsBlob, downloadFilename);
  }
}
