import { retrieveTokens } from "amazon-cognito-passwordless-auth/storage";
import type { ReteynSchema } from "../schema";
import { parseTruthyResponse } from "../dao";
import { jwtDecode } from "jwt-decode";
import { requestSignInLink } from "amazon-cognito-passwordless-auth/magic-link";
import { Route } from "../config";
import { V6Client } from "@aws-amplify/api-graphql";

export async function createQuizAndSendToCurrentUser(
  client: V6Client<ReteynSchema>,
  request: {
    questionId: string;
    studentDashboardOrigin: string;
  },
): Promise<ReteynSchema["Test"]["type"]> {
  const tokens = await retrieveTokens();
  const { email } = jwtDecode<{ email: string }>(tokens!.idToken as string);
  const [existingContact] = await parseTruthyResponse(
    client.models.Contact.listContactByEmail({ email }),
  );
  const contact =
    existingContact ||
    (await parseTruthyResponse(client.models.Contact.create({ email })));
  const test = await parseTruthyResponse(
    client.models.Test.create({
      questionId: request.questionId,
      contactId: contact.id,
      owner: contact.owner as string,
      userRequested: true,
    }),
  );
  const linkResponse = requestSignInLink({
    username: email,
    redirectUri: `${request.studentDashboardOrigin}/${Route.Submit}/${test.id}`,
  });
  await linkResponse.signInLinkRequested;
  return test as ReteynSchema["Test"]["type"];
}
