
<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let title;
    export const dispatch = createEventDispatcher<{"click": null}>()
</script>

<style>
    .clickable {
      cursor: pointer;
    }
</style>

<a class="card clickable join-item border border-base-300 hover:bg-base-300" on:click={() => dispatch("click")}>
    <div class="card-body px-4 py-4" >
        <div class="flex flex-row w-full justify-between">
            <span class="inline-block align-middle">{title}</span>
            <div class="card-actions flex flex-row items-center justify-end flex-wrap-reverse">
               <slot/>
            </div>
        </div>
    </div>
</a>