import { Field } from "@aws-amplify/core/dist/esm/singleton/API/types";
import { inputTypeMappings } from "./inputTypeMappings";
import { StringFieldType } from "./StringFieldType";

export function isEditableField(field: Field): boolean {
  return (
    !!(
      inputTypeMappings[field.type as StringFieldType] ||
      (field.type as { enum: string }).enum
    ) &&
    !field.isArray &&
    !field.isReadOnly
  );
}
