import { signUp } from "amazon-cognito-passwordless-auth/cognito-api";
import { signIn } from "./signIn";

export async function signUpAndSignIn(request: {
  email: string;
  redirectUri?: string;
  crypto?: { randomUUID(): string };
}): Promise<void> {
  try {
    const crypto = request.crypto || window.crypto;
    const response = await signUp({
      username: request.email,
      password: crypto.randomUUID(),
    });
    const result = await response.json();
    if (!response.ok) {
      console.error(result);
      throw new Error("Failed to sign up");
    }
    await signIn(request);
  } catch (err) {
    if ((err as { name: string }).name === "UsernameExistsException") {
      await signIn(request);
    } else {
      throw err;
    }
  }
}
