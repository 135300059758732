<script lang="ts">
    import isEmail from 'validator/es/lib/isEmail';
    import { signUpAndSignIn } from '../authentication';
    import {Hero} from '../reteyn-components';
    let email: string;
    let signInPromise: Promise<void> | undefined;
</script>

<Hero>
    <h1 class="text-5xl font-bold">Hello there</h1>
    <p class="py-6">
        {#if signInPromise}
            {#await signInPromise}
                Signing in... <span class="loading loading-ring loading-xs"></span>
            {:then}
                Email sent to {email} with instructions.
            {/await}
        {:else}
            Sign in to Reteyn.
        {/if}
    </p>

    <form on:submit|preventDefault={() => signInPromise = signUpAndSignIn({email})}>
        <fieldset disabled={!!signInPromise}>
            <div class="join">
                <label class="input join-item input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                    <input
                        type="email"
                        size="25"
                        class="grow"
                        placeholder="Email"
                        autocomplete="email"
                        required
                        bind:value={email}
                    />
                </label>
                <button class="btn btn-primary join-item" disabled={!(email && isEmail(email))}>Go</button>
            </div>
        </fieldset>
    </form>
</Hero>
