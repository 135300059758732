import type { ReteynSchema } from "../schema/index.js";
import { CourseInput } from "./CourseInput.js";
import {
  parseTruthyResponse,
  toIterable,
  collect,
  sortTruthy,
} from "../dao/index.js";
import { V6Client } from "@aws-amplify/api-graphql";
import { defaultMessageTemplate } from "./defaultMessageTemplate.js";
import { defaultPreviewTemplate } from "./defaultPreviewTemplate.js";
import { defaultSubjectTemplate } from "./defaultSubjectTemplate.js";
import { defaultContext } from "./defaultContext.js";
import { TopicInput } from "./TopicInput.js";
import { QuestionInput } from "./QuestionInput.js";
import { AnswerInput } from "./AnswerInput.js";
import isEqual from "lodash/isEqual.js";
import cloneDeep from "lodash/cloneDeep.js";
import flatten from "lodash/flatten.js";
import { reteynerSelectionSet } from "./reteynerSelectionSet.js";
import type { Reteyner } from "./Reteyner.js";

export class CourseIngester {
  constructor(private client: V6Client<ReteynSchema>) {}

  readonly validationErrorMessage = "Ingested Reteyner doesn't match input";

  async findOrCreateQuizTemplate(
    organisationId: string,
  ): Promise<ReteynSchema["QuizTemplate"]["type"]> {
    const existingTemplates = await collect(
      toIterable((nextToken) =>
        this.client.models.QuizTemplate.list({
          nextToken,
          filter: {
            organisationId: {
              eq: organisationId,
            },
          },
        }),
      ),
    );
    return (
      existingTemplates[0] ||
      parseTruthyResponse(
        this.client.models.QuizTemplate.create({
          title: "Default template",
          organisationId,
          subject: defaultSubjectTemplate,
          preview: defaultPreviewTemplate,
          body: defaultMessageTemplate,
          context: defaultContext,
        }),
      )
    );
  }

  async findReteyner(reteynerId: string): Promise<Reteyner> {
    return parseTruthyResponse(
      this.client.models.Reteyner.get(
        {
          id: reteynerId,
        },
        {
          selectionSet: reteynerSelectionSet,
        },
      ),
    );
  }

  async ingestQuestion(
    questionInput: QuestionInput,
    topicId: string,
    questionIndex: number,
  ): Promise<ReteynSchema["Question"]["type"]> {
    const question = await parseTruthyResponse(
      this.client.models.Question.create({
        index: questionIndex,
        text: questionInput.question,
        topicId,
        random: true,
      }),
    );
    await Promise.all(
      questionInput.answers.map((answerInput, index) =>
        this.ingestAnswer(answerInput, question.id, index),
      ),
    );
    return question;
  }

  async ingestAnswer(
    answerInput: AnswerInput,
    questionId: string,
    index: number,
  ): Promise<ReteynSchema["Answer"]["type"]> {
    return parseTruthyResponse(
      this.client.models.Answer.create({
        index,
        questionId,
        text: answerInput.answer,
        correct: answerInput.correct,
        response: answerInput.response,
      }),
    );
  }

  async ingestTopic(
    topicInput: TopicInput,
    reteynerId: string,
    index: number,
  ): Promise<ReteynSchema["Topic"]["type"]> {
    const topic = await parseTruthyResponse(
      this.client.models.Topic.create({
        index,
        text: topicInput.topic,
        reteynerId,
        content: topicInput.content,
      }),
    );
    await Promise.all(
      topicInput.quizzes.map((questionInput, index) =>
        this.ingestQuestion(questionInput, topic!.id, index),
      ),
    );
    return topic;
  }

  toCourseInput(reteyner: Reteyner): CourseInput {
    const publication = reteyner?.publications[0]?.publication;
    return {
      title: publication?.title || "",
      author: publication?.author || "",
      topics: sortTruthy(reteyner.topics).map((topic) => ({
        topic: topic.text,
        quizzes: sortTruthy(topic.questions).map((question) => ({
          question: question.text,
          answers: sortTruthy(question.answers).map((answer) => ({
            answer: answer.text,
            correct: answer.correct,
            response: answer.response,
          })),
        })),
        content: topic.content,
      })),
    };
  }

  async validateImport(
    input: CourseInput,
    reteynerId: string,
  ): Promise<Reteyner> {
    const reteyner = await this.findReteyner(reteynerId);
    const ingested = this.toCourseInput(reteyner);
    if (!isEqual(input, ingested)) {
      console.error(ingested);
      throw new Error(this.validationErrorMessage);
    }
    return reteyner;
  }

  async createNew(
    organisationId: string,
    input: CourseInput,
  ): Promise<ReteynSchema["Reteyner"]["type"]> {
    const publication = await parseTruthyResponse(
      this.client.models.Publication.create({
        title: input.title,
        author: input.author,
        organisationId,
      }),
    );
    const reteyner = await parseTruthyResponse(
      this.client.models.Reteyner.create({
        organisationId,
        name: publication.title,
      }),
    );

    await parseTruthyResponse(
      this.client.models.PublicationReteyner.create({
        publicationId: publication.id,
        reteynerId: reteyner.id,
      }),
    );
    const template = await this.findOrCreateQuizTemplate(organisationId);
    await parseTruthyResponse(
      this.client.models.ReteynerQuizTemplate.create({
        reteynerId: reteyner.id,
        quizTemplateId: template.id,
      }),
    );

    await Promise.all(
      input.topics.map((topicInput, index) =>
        this.ingestTopic(topicInput, reteyner.id, index),
      ),
    );
    try {
      await this.validateImport(input, reteyner.id);
    } catch (err) {
      if ((err as Error).message === this.validationErrorMessage) {
        await Promise.all([
          parseTruthyResponse(
            this.client.models.Reteyner.delete({ id: reteyner.id }),
          ),
          parseTruthyResponse(
            this.client.models.Publication.delete({ id: publication.id }),
          ),
        ]);
      }
      throw err;
    }
    return reteyner;
  }

  toNextIndex(items: { index: number }[]): number {
    return Math.max(0, ...items.map((item) => item.index + 1));
  }

  toProposed(request: {
    existing: TopicInput[];
    updates: TopicInput[];
  }): TopicInput[] {
    const proposed = cloneDeep(request.existing);
    for (const topic of request.updates) {
      const existingTopic = proposed.find((t) => t.topic === topic.topic);
      if (existingTopic) {
        const existingQuizText = existingTopic.quizzes.map((q) => q.question);
        const newQuizzes = topic.quizzes.filter(
          (q) => !existingQuizText.includes(q.question),
        );
        existingTopic.quizzes.push(...newQuizzes);
      } else {
        proposed.push(topic);
      }
    }
    return proposed;
  }

  async addQuestionsToReteyner(
    reteyner: Reteyner,
    updates: TopicInput[],
  ): Promise<{
    questions: ReteynSchema["Question"]["type"][];
    topics: ReteynSchema["Topic"]["type"][];
  }> {
    const nextTopicIndex = this.toNextIndex(reteyner.topics);
    const newTopics = updates.filter(
      (topic) => !reteyner.topics.find((t) => t.text === topic.topic),
    );
    const ingestedTopics = await Promise.all(
      newTopics.map((t, index) =>
        this.ingestTopic(t, reteyner.id, nextTopicIndex + index),
      ),
    );
    const existingTopics = updates
      .map((t) => [reteyner.topics.find((topic) => topic.text === t.topic), t])
      .filter(([topic]) => topic) as [
      NonNullable<Reteyner["topics"][number]>,
      TopicInput,
    ][];
    const batches = await Promise.all(
      existingTopics.map(([existingTopic, update]) =>
        Promise.all(
          update.quizzes
            .filter(
              (quiz) =>
                !existingTopic.questions.find((q) => q.text === quiz.question),
            )
            .map((quiz, quizIndex) =>
              this.ingestQuestion(
                quiz,
                existingTopic.id,
                this.toNextIndex(existingTopic.questions) + quizIndex,
              ),
            ),
        ),
      ),
    );
    return {
      topics: ingestedTopics,
      questions: flatten(batches),
    };
  }

  async addQuestions(
    reteyner: Reteyner,
    updates: TopicInput[],
  ): Promise<Reteyner> {
    const original = this.toCourseInput(reteyner);
    const proposed = this.toProposed({
      existing: original.topics,
      updates,
    });
    const { questions, topics } = await this.addQuestionsToReteyner(
      reteyner,
      updates,
    );
    try {
      return await this.validateImport(
        {
          ...original,
          topics: proposed,
        },
        reteyner.id,
      );
    } catch (err) {
      if ((err as Error).message === this.validationErrorMessage) {
        await Promise.all(
          [
            ...questions.map((q) =>
              this.client.models.Question.delete({ id: q.id }),
            ),
            ...topics.map((t) => this.client.models.Topic.delete({ id: t.id })),
          ].map((r) => parseTruthyResponse(r)),
        );
      }
      throw err;
    }
  }

  async ingest(
    organisationId: string,
    input: CourseInput,
  ): Promise<ReteynSchema["Reteyner"]["type"]> {
    return this.createNew(organisationId, input);
  }
}
