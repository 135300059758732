import { V6Client } from "@aws-amplify/api-graphql";
import { ModelName, ReteynSchema } from "../schema/index.js";
import { InputType, parseReturnValue } from "../dao/index.js";
import { ParentPathResolver } from "./ParentPathResolver.js";
import get from "lodash/get.js";

export class OrganisationResolver {
  constructor(
    private client: V6Client<ReteynSchema>,
    private pathResolver: ParentPathResolver,
  ) {}

  async resolve<T extends ModelName>(
    modelName: T,
    item: ReteynSchema[T]["type"],
    keys: (keyof ReteynSchema["Organisation"]["type"] | "*")[] = ["*"],
  ): Promise<ReteynSchema["Organisation"]["type"] | undefined> {
    if (modelName === ModelName.Organisation) {
      return item as ReteynSchema["Organisation"]["type"];
    }
    const parentPath = this.pathResolver.toParentPath({
      from: modelName,
      to: ModelName.Organisation,
    });
    if (parentPath.length) {
      const value = await parseReturnValue(
        this.client.models[modelName as "Organisation"].get(item, {
          selectionSet: keys.map((key) =>
            [...parentPath, key].join("."),
          ) as any,
        }),
      );
      return get(value, parentPath);
    }
  }
}
