import type { ModelIntrospectionSchema } from "@aws-amplify/core/internals/utils";
import { ModelName, organisationIdField, Role } from "../schema/index.js";
import { AuthorisationRequest } from "./AuthorisationRequest.js";

export class AuthorisationResolver {
  constructor(
    private modelIntrospection: ModelIntrospectionSchema,
    private groups: string[],
  ) {}

  isOrganisationEntity(modelName: ModelName): boolean {
    return (
      this.modelIntrospection.models[modelName].fields[organisationIdField]
        ?.type === "ID"
    );
  }

  parse(group: string): { role: Role; organisationId: string } | undefined {
    const [organisationId, role] = group.split("_");
    return role && organisationId && Object.values(Role).includes(role as Role)
      ? { role: role as Role, organisationId }
      : undefined;
  }

  toOrgRoles(groups: string[], organisationId: string): Role[] {
    return groups
      .map((r) => this.parse(r))
      .filter((r) => r && r.organisationId === organisationId)
      .map((r) => r?.role)
      .filter((r) => r) as Role[];
  }

  isOwner<T extends ModelName>(request: AuthorisationRequest<T>): boolean {
    return this.toOrgRoles(this.groups, request.organisationId).includes(
      Role.Owner,
    );
  }

  canEdit<T extends ModelName>(request: AuthorisationRequest<T>): boolean {
    return (
      !this.isOrganisationEntity(request.modelName) || this.isOwner(request)
    );
  }
}
