import {
  AssociationBelongsTo,
  ModelFieldType,
  ModelIntrospectionSchema,
} from "@aws-amplify/core/internals/utils";
import { toTitleField } from "./toTitleField";
import { ModelType } from "./ModelType";
import { parseReturnValue } from "../dao";
import { ModelName, type ReteynSchema } from "../schema";
import type { V6Client } from "@aws-amplify/api-graphql";
import { parseTitleField } from "./parseTitleField";

export async function toTitle(
  client: V6Client<ReteynSchema>,
  schema: ModelIntrospectionSchema,
  modelName: ModelName,
  object: ModelType,
  excludedTypes: ModelName[],
): Promise<string> {
  const modelSchema = schema.models[modelName];
  const titleField = toTitleField(modelSchema);
  if (titleField) {
    return parseTitleField(titleField, object);
  }
  const otherParentFields = Object.values(modelSchema.fields).filter(
    (f) =>
      f.association?.connectionType === "BELONGS_TO" &&
      !excludedTypes.includes((f.type as ModelFieldType).model as ModelName),
  );

  for (const parentField of otherParentFields) {
    const parentModelName = (parentField.type as ModelFieldType).model;
    const parentModelSchema = schema.models[parentModelName];
    const parentTitleField = toTitleField(parentModelSchema);
    const parentIdField = parentModelSchema.primaryKeyInfo.primaryKeyFieldName;
    if (parentTitleField) {
      const parentId = (
        parentField.association as AssociationBelongsTo
      ).targetNames
        .map((key) => object[key as keyof ModelType])
        .find((id) => !!id);
      if (parentId) {
        const indexField: keyof ReteynSchema["Answer"]["type"] = "index";
        const selectionSet = [
          parentTitleField,
          Object.values(parentModelSchema.fields).find(
            (f) => f.type === "Int" && f.name === indexField,
          ),
        ]
          .filter((v) => v)
          .map((f) => f!.name);
        const result = await parseReturnValue(
          client.models[parentModelName as ModelName].get(
            { [parentIdField]: parentId },
            { selectionSet },
          ),
        );
        return parseTitleField(parentTitleField, result);
      }
    }
  }

  return object[
    modelSchema.primaryKeyInfo.primaryKeyFieldName as keyof ModelType
  ] as string;
}
