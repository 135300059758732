import type { V6Client } from "@aws-amplify/api-graphql";
import type { ReteynSchema } from "../schema";
import { parseReturnValue } from "../dao";
import { Results } from "./Results";
import cloneDeep from "lodash/cloneDeep";
import flattenDeep from "lodash/flattenDeep";
import { Observable, concatMap, filter, from, scan, startWith } from "rxjs";
import { filterNullish } from "./filterNullish";

export class ResultResolver {
  constructor(private client: V6Client<ReteynSchema>) {}

  async listResults(reteynerId: string): Promise<Results | null> {
    return parseReturnValue(
      this.client.models.Reteyner.get(
        { id: reteynerId },
        {
          selectionSet: [
            "name",
            "topics.text",
            "topics.index",
            "topics.questions.text",
            "topics.questions.index",
            "topics.questions.answers.id",
            "topics.questions.answers.correct",
            "topics.questions.answers.text",
            "topics.questions.answers.index",
            "topics.questions.answers.submissions.createdAt",
            "topics.questions.answers.submissions.test.contact.lastName",
            "topics.questions.answers.submissions.test.contact.firstName",
            "topics.questions.answers.submissions.test.contact.email",
          ],
        },
      ),
    );
  }

  mergeResults(
    results: Results,
    submission: {
      answerId: string;
      createdAt: string;
      test: {
        contact: {
          firstName: string;
          lastName: string;
          email: string;
        };
      };
    },
  ): Results {
    const output = cloneDeep(results);
    const answers = flattenDeep(
      output.topics.map((topic) =>
        topic.questions.map((question) => question.answers),
      ),
    );
    const answer = answers.find((answer) => answer.id === submission.answerId);
    if (answer) {
      answer.submissions.push({
        test: submission.test,
        createdAt: submission.createdAt,
      });
    }
    return output;
  }

  loadResults(reteynerId: string): Observable<Results> {
    const resultObservable = from(this.listResults(reteynerId)).pipe(
      filterNullish(),
    );
    const submissionSource = this.client.models.Submission.onCreate().pipe(
      concatMap((v) =>
        parseReturnValue(
          this.client.models.Submission.get(v, {
            selectionSet: [
              "answerId",
              "createdAt",
              "answer.question.topic.reteynerId",
              "test.contact.firstName",
              "test.contact.lastName",
              "test.contact.email",
            ],
          }),
        ),
      ),
      filterNullish(),
      filter((s) => s.answer.question.topic.reteynerId === reteynerId),
    );
    return resultObservable.pipe(
      concatMap((results) =>
        submissionSource.pipe(
          scan(
            (last, submission) => this.mergeResults(last, submission),
            results,
          ),
          startWith(results),
        ),
      ),
    );
  }
}
