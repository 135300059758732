import { Route } from "../config";
import { ModelName } from "../schema";

export function toEditRoute(request: {
  organisationId: string;
  model: ModelName;
  id: string;
}): string {
  return `/${Route.Edit}/${request.organisationId}/${request.model}/${request.id}`;
}
