<script lang="ts">
    import { onMount } from "svelte";
    import SignIn from "./SignIn.svelte";
    import {LoadingScreen} from "../reteyn-components";
    import { getTokens } from "../authentication";
    import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';
    import { sessionStore } from "./sessionStore";

    async function start(): Promise<AuthSession | undefined> {
        const tokens = await getTokens();
        if (tokens) {
            return fetchAuthSession();
        }
    }
    onMount(() => {
        sessionStore.set(start())
    });
</script>

{#await $sessionStore}
    <LoadingScreen></LoadingScreen>
{:then session}
    {#if session}
        <slot {session}/>
    {:else}
        <SignIn></SignIn>
    {/if}
{/await}
