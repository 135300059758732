import type {
  Field,
  SchemaModel,
} from "@aws-amplify/core/dist/esm/singleton/API/types";
import type { StringFieldType } from "./StringFieldType";

export function toTitleField(model: SchemaModel): Field | undefined {
  return Object.values(model.fields).find(
    (f) =>
      ["String", "AWSEmail"].includes(f.type as StringFieldType) &&
      f.isRequired,
  );
}
