import type {
  ListReturnValue,
  SingularReturnValue,
} from "@aws-amplify/data-schema-types";
import { parseResponse } from "./parseResponse.js";
import { rejectNullResult } from "./rejectNullResult.js";

export async function parseTruthyResponse<
  V extends SingularReturnValue<any> | ListReturnValue<any>,
>(response: V): Promise<NonNullable<Awaited<V>["data"]>> {
  return rejectNullResult(parseResponse(await response));
}
