<script lang="ts">
    import { fade } from "svelte/transition";
    export let title: string;
</script>

<style>
    .tab-item {
        transition: visibility 0s, opacity 0.5s linear;
    }
</style>
<div data-title={title} class="tab-item full-height" in:fade>
    <slot />
</div>