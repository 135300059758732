import { StringFieldType } from "./StringFieldType";

export const inputTypeMappings: Partial<Record<StringFieldType, string>> = {
  Boolean: "checkbox",
  Int: "number",
  String: "text",
  Float: "number",
  AWSEmail: "email",
  AWSDate: "date",
  AWSDateTime: "datetime-local",
  AWSTime: "time",
  AWSURL: "url",
  AWSTimestamp: "datetime-local",
};
