<script lang="ts">
    import { createEventDispatcher } from "svelte";

    export let selected: number = 0;
    let slot: HTMLDivElement;
    let children: HTMLElement[];
    export const dispatch = createEventDispatcher<{tab: number}>();
    $: children = slot ? [...slot.querySelectorAll(".tab-item")] : []
    $: children.forEach((child, index) => child.style.display = index === selected ? "block" : "none")

    function onTabChange(tabIndex: number) {
        selected = tabIndex;
        dispatch("tab", selected);
    }
</script>
<style>
    .tab-body {
        overflow-y: scroll;
    }
</style>
{#if children && children.length > 1}
    <div role="tablist" class="tabs tabs-lifted">
        {#each children as tab, index}
            <a role="tab" class="tab" tabindex={index} class:tab-active={selected===index} on:click={()=> onTabChange(index)}>{tab.getAttribute("data-title")}</a>
        {/each}
    </div>
{:else}
    {#each children as tab}
        <div class="label">
            <div class="label-text">{tab.getAttribute("data-title")}</div> 
        </div>
    {/each}
{/if}
<div bind:this={slot} class="tab-body">
    <slot />
</div>