<script lang="ts">
    import JsonEditor from "./JsonEditor.svelte";
    import { CourseIngester, CourseInput } from "../course-ingester";
    import { ModelName, type ReteynSchema } from "../schema";
    import { navigate } from "svelte-navigator";
    import { toEditRoute } from "./toEditRoute";

    let coursePromise: Promise<ReteynSchema["Reteyner"]["type"] | null>;
    let content: {text: string};
    export let organisationId: string;
    export let ingester: CourseIngester;
    async function ingest(): Promise<ReteynSchema["Reteyner"]["type"] | null> {  
        const reteyner = await ingester.ingest(
            organisationId,
            JSON.parse(content.text) as CourseInput,
        );
        navigate(toEditRoute({model: ModelName.Reteyner, id: reteyner!.id, organisationId}))
        return reteyner;
    }
</script>

<div class="flex flex-col grow overflow-y-scroll">
    <div class="flex flex-col grow overflow-y-scroll">
        <JsonEditor
            mainMenuBar={false}
            bind:content
            mode = "text"
            readOnly={!!coursePromise}
        ></JsonEditor>
    </div>
    <div class="flex flex-row">
        <form on:submit|preventDefault={() => (coursePromise = ingest())}>
            <fieldset disabled={!!coursePromise}>
                <div class="join">
                    <button
                        class="btn btn-primary join-item"
                        disabled={!!coursePromise}>Create</button
                    >
                </div>
            </fieldset>
        </form>
        {#if coursePromise}
            {#await coursePromise}
                <span class="loading loading-ring loading-lg"></span>
            {/await}
        {/if}
    </div>
</div>
