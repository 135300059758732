<script lang="ts">
    import { ReteynSchema } from "../schema";
    import {LoadingScreen} from "../reteyn-components";
    import { Results } from "./Results";
    import { ResultResolver } from "./ResultResolver";
    import { Observable, tap } from "rxjs";
    import EditorCard from "./EditorCard.svelte";
    import { sortItems } from "../dao";
    import AnswerCountsChart from "./AnswerCountsChart.svelte";
    import { V6Client } from "@aws-amplify/api-graphql";
    import { ResultDownloader } from "./ResultDownloader";

    export let reteynerId: string;
    export let client: V6Client<ReteynSchema>;
    let resultResolver: ResultResolver;
    const downloader = new ResultDownloader();
    let resultsObservable: Observable<Results>;
    $: resultResolver = new ResultResolver(client);
    $: resultsObservable = resultResolver.loadResults(reteynerId);

</script>

{#if $resultsObservable}
    {@const reteyner = $resultsObservable}
    {@const topics = sortItems(reteyner.topics)}
    <EditorCard title={reteyner.name}>
        <div class="card-actions justify-end">
            <button class="btn btn-primary" on:click={() => downloader.download(reteyner)}>Download</button>
        </div>
        <div class="py-3">
            {#each topics as topic}
                {@const questions = sortItems(topic.questions)}
                <div class="flex flex-col gap-3">
                    <h2 class="card-title">{topic.index + 1}. {topic.text}</h2>
                    {#each questions as question}
                        <div class="py-3 flex flex-col">
                            <h3>Q{question.index + 1}: {question.text}</h3>
                            <div class="card">
                                <div class="card-body">
                                    <AnswerCountsChart results={question}></AnswerCountsChart>
                                </div>
                            </div>
                        </div>
                    {/each}
                </div>
            {/each}
        </div>
    </EditorCard>

{:else} 
    <LoadingScreen />
{/if}
