import { ListClient } from "./ListClient";
import { ListOptions } from "./ListOptions.js";
import { toIterable } from "./toIterable.js";

export function list<I extends ListOptions, O>(
  client: ListClient<I, O>,
  options?: I,
): AsyncIterable<O> {
  return toIterable((nextToken) => client.list({ ...options, nextToken } as I));
}
