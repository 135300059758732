<script lang="ts">
    import {onMount} from "svelte";
    export let mainMenuBar: boolean;
    export let content: {text: string};
    export let readOnly: boolean;
    export let mode: "text" | "tree";

    let JSONEditor: any;
    let Mode: any;

    onMount(async () => {
        const jsonEditor = await import("svelte-jsoneditor");
        JSONEditor = jsonEditor.JSONEditor;
        Mode = jsonEditor.Mode;
    })
</script>

<svelte:component this={JSONEditor} {mainMenuBar} {mode} bind:content {readOnly}>
</svelte:component>
