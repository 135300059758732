import { V6Client } from "@aws-amplify/api-graphql";
import { listAll } from "../dao";
import { ModelName, organisationIdField, ReteynSchema } from "../schema";
import { ModelIntrospectionSchema } from "@aws-amplify/core/dist/esm/singleton/API/types";

export function listItems<T extends ModelName>(
  client: V6Client<ReteynSchema>,
  request: {
    modelIntrospection: ModelIntrospectionSchema;
    modelName: T;
    organisationId?: string;
  },
): Promise<ReteynSchema[T]["type"][]> {
  const { organisationId, modelIntrospection, modelName } = request;
  const options =
    organisationId &&
    modelIntrospection.models[modelName].fields[organisationIdField]
      ? { filter: { organisationId: { eq: organisationId } } }
      : {};
  return listAll(client.models[modelName], options);
}
