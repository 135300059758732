<script lang="ts">
    import { useLocation, navigate } from "svelte-navigator";
    import MoreIcon from "./MoreIcon.svelte";
    import {signOut} from "amazon-cognito-passwordless-auth/common";
    const location = useLocation();
    async function onSingOutClick() {
        const {signedOut} = signOut();
        await signedOut;
        window.location.reload();
    }

    
</script>
<style>
    .dropdown-content {
        width: 200px;
    }
    .dropdown-content :global(.btn) {
        justify-content: left;
    }
</style>

<div class="navbar bg-base-100 flex flex-row justify-center">
    <div class="w-full max-w-screen-xl px-8 flex justify-between gap-1">
        <div class="flex gap-1">
            <button
                class="btn"
                on:click={() => navigate(`/`)}
            >
                Organisations
            </button>
        </div>
        <div class="dropdown dropdown-end">
            <button class="btn btn-square btn-ghost">
                <MoreIcon></MoreIcon>
            </button>
            <div class="dropdown-content z-[1] p-2 flex flex-col gap-1 bg-base-100 shadow">
                <slot location={$location}/>
                <button class="btn btn-ghost" on:click={() => onSingOutClick()}>Sign out</button>
            </div>
        </div>
    </div>
</div>
