import type { SchemaModel } from "@aws-amplify/core/internals/utils";

export function toOwnerFieldName(model: SchemaModel): string | undefined {
  const authAttributes =
    model.attributes?.filter((a) => a.type === "auth") || [];
  for (const attribute of authAttributes) {
    for (const rule of attribute.properties?.rules || []) {
      if (rule.ownerField) {
        return rule.ownerField;
      }
    }
  }
}
