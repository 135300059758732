<script lang="ts">
    import { Results } from "./Results";
    import { sortItems } from "../dao";

    export let results: Results["topics"][0]["questions"][0];
    let answers: Results["topics"][0]["questions"][0]["answers"];
    function toLabelText(answer: Results["topics"][0]["questions"][0]["answers"][0]): string {
        return `A${answer.index + 1}. ${answer.text}`;
    }

    $: answers = sortItems(results.answers);
    
</script>
<div class="flex flex-col gap-3">
    {#if answers && answers.find(answer => answer.submissions.length)}
        {@const max = Math.max(...answers.map(answer => answer.submissions.length))}
        {@const total = answers.map(answer => answer.submissions.length).reduce((t, s) => t + s, 0)}
        {#each answers as answer}
            {@const title = toLabelText(answer)}
            {@const count = answer.submissions.length}
            <div class="w-full flex flex-col gap-1">
                <div class="flex justify-between w-full gap-1">
                    <div>{title}</div>
                    <div>{Math.round(100 * count / total)}%</div>
                </div>
                <progress class="progress w-full {answer.correct ? "progress-success" : "progress-error"}" {max} value={count}></progress>
            </div>
        {/each}
        <div class="self-end">Total: {total}</div>
    {:else}
        <p>No submissions yet</p>
    {/if}
</div>