import { ListClient } from "./ListClient.js";
import { ListOptions } from "./ListOptions.js";
import { collect } from "./collect.js";
import { list } from "./list.js";

export function listAll<I extends ListOptions, O>(
  client: ListClient<I, O>,
  options?: I,
): Promise<O[]> {
  return collect(list(client, options));
}
