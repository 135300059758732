<script lang="ts">
    import "../node_modules/jsondiffpatch/lib/formatters/styles/html.css";
    import { diff } from "jsondiffpatch";
    import { format } from "jsondiffpatch/formatters/html";
    import {
        CourseIngester,
        type Reteyner,
        type TopicInput,
    } from "../course-ingester/index.js";
    import LoadingScreen from "../reteyn-components/LoadingScreen.svelte";
    import JsonEditor from "./JsonEditor.svelte";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher<{ update: Reteyner }>();
    export let reteynerId: string;
    export let ingester: CourseIngester;
    const defaultTopics: TopicInput[] = [
        {
            topic: "foo",
            content: "bar",
            quizzes: [
                {
                    question: "foo bar?",
                    answers: [
                        {
                            answer: "a",
                            correct: false,
                            response: "foo bar a"
                        },
                        {
                            answer: "b",
                            correct: true,
                            response: "foo baz b"
                        }
                    ]
                }
            ],
        },
    ];
    let content: { text: string } = {
        text: JSON.stringify(
            {
                topics: defaultTopics,
            },
            null,
            2,
        ),
    };
    let reteynerPromise: Promise<Reteyner>;
    let proposed: TopicInput[] | undefined;

    $: reteynerPromise = ingester.findReteyner(reteynerId);

    async function applyChanges(reteyner: Reteyner) {
        reteynerPromise = ingester.addQuestions(reteyner, proposed || []);
        dispatch("update", await reteynerPromise);
    }
</script>

{#await reteynerPromise}
    <LoadingScreen></LoadingScreen>
{:then reteyner}
    <div class="flex flex-col grow overflow-y-scroll border">
        <div class="flex flex-col grow overflow-y-scroll">
            {#if !proposed}
                <JsonEditor
                    mainMenuBar={false}
                    bind:content
                    readOnly={false}
                    mode="text"
                ></JsonEditor>
            {:else}
                {@const original = ingester.toCourseInput(reteyner)}
                {@const delta = diff(original, {
                    ...original,
                    topics: proposed,
                })}
                {@const html = format(delta, original)}
                <div class="pl-12 bg-base-200">
                    <div class="bg-base-100 py-2 border">
                        {@html html}
                    </div>
                </div>
            {/if}
        </div>
        <div class="flex flex-row">
            <fieldset>
                <div class="join">
                    <button
                        class="btn btn-neutral join-item"
                        disabled={!proposed}
                        on:click={() => {
                            proposed = undefined;
                        }}
                    >
                        Back
                    </button>
                    {#if proposed}
                        <button
                            class="btn btn-primary join-item"
                            on:click={() => applyChanges(reteyner)}
                        >
                            Apply
                        </button>
                    {:else}
                        <button
                            class="btn btn-primary join-item"
                            on:click={() =>
                                (proposed = ingester.toProposed({
                                    existing:
                                        ingester.toCourseInput(reteyner).topics,
                                    updates: JSON.parse(content.text).topics,
                                }))}>Review changes</button
                        >
                    {/if}
                </div>
            </fieldset>
            {#if reteynerPromise}
                {#await reteynerPromise}
                    <span class="loading loading-ring loading-lg"></span>
                {/await}
            {/if}
        </div>
    </div>
{/await}
