import { requestSignInLink } from "amazon-cognito-passwordless-auth/magic-link";

export async function signIn(request: {
  email: string;
  redirectUri?: string;
}): Promise<string> {
  const result = requestSignInLink({
    username: request.email,
    redirectUri: request.redirectUri,
  });
  return result.signInLinkRequested;
}
