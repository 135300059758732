<script lang="ts">
    import type { ModelName, ReteynSchema } from "../schema";
    import { createEventDispatcher } from "svelte";
    import type { ModelIntrospectionSchema } from "@aws-amplify/core/internals/utils";
    import RemoveButton from "./RemoveButton.svelte";
    import { ModelType } from "./ModelType";
    import ModelItem from "./ModelItem.svelte";
    import { V6Client } from "@aws-amplify/api-graphql";
    export let model: ModelName;
    export let modelSchema: ModelIntrospectionSchema;
    export let client: V6Client<ReteynSchema>;
    export const dispatch = createEventDispatcher<{select: ModelType, remove: ModelType}>()
    export let items: ModelType[];
    export let allowRemove: (item: ModelType) => boolean = () => false;
    export let excludedTypes: ModelName[];
</script>

<div class="join join-vertical w-full">
    {#each items as object}
        <ModelItem {client} schema={modelSchema} modelName={model} {object} {excludedTypes} on:click={() => dispatch("select", object)}>
            {#if allowRemove(object)}
                <RemoveButton on:click={() => dispatch("remove", object)}>
                </RemoveButton>
            {/if}
        </ModelItem>
    {/each}
</div>