<script lang="ts">
    import { Field, ModelIntrospectionSchema } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import { ModelName, ReteynSchema } from "../schema";
    import { createEventDispatcher } from "svelte";
    import { isEditableField } from "./isEditableField";
    import FormField from "./FormField.svelte";
    import AmplifyFormElement from "./AmplifyFormElement.svelte";
    import { ElementValue } from "./ElementValue";
    import { ModelType } from "./ModelType";
    import { toOwnerFieldName } from "../json-schema";
    import { toStringValue } from "./toStringValue";
    import { V6Client } from "@aws-amplify/api-graphql";
    import { formatTitle } from "./formatTitle";

    export let data: Partial<ModelType>;
    export let modelSchema: ModelIntrospectionSchema;
    export let model: ModelName;
    export let client: V6Client<ReteynSchema>;

    const dispatch = createEventDispatcher<{submit: Partial<ModelType>, update: {field: Field, value: ElementValue}}>()

    let form: HTMLFormElement;

    function isOwnerField(key: string): boolean {
        const ownershipField = toOwnerFieldName(modelSchema.models[model]);
        return ownershipField === key;
    }

    function toObject(formData: FormData): Partial<ModelType> {
        const baseObject = Object.fromEntries(formData) as Partial<ModelType>;
        const booleanFields = Object.values(modelSchema.models[model].fields).filter(f => isEditableField(f) && f.type === "Boolean");
        const booleans = Object.fromEntries(booleanFields.map(f => [f.name, parseString(f, formData.get(f.name) as string)])) as Partial<ModelType>
        return {
            ...baseObject,
            ...booleans
        };
    }

    function parseBoolean(value: string): boolean {
        return value === "on";
    }

    function parseString(field: Field, value: string): ElementValue {
        return field.type === "Boolean" ? parseBoolean(value) : value;
    }

    
    export function getForm(): HTMLFormElement {
        return form;
    }

    function toFieldValue(field: Field): ElementValue | null {
        const value = data[field.name as keyof ModelType];
        return typeof value === "undefined" ? null : value as ElementValue;
    }

    function onUpdate(field: Field, element: HTMLInputElement | HTMLTextAreaElement) {
        if (element.reportValidity()) {
            const stringValue = toStringValue(element as HTMLInputElement);
            const value = parseString(field, stringValue);
            dispatch("update", {
                field,
                value
            });
            form.requestSubmit();
        }
    }

</script>
<form bind:this={form} on:submit|preventDefault={() => dispatch("submit", toObject(new FormData(form)))}>
    {#each Object.values(modelSchema.models[model].fields) as field}
        {#if isEditableField(field) && !isOwnerField(field.name)}
            {@const value = toFieldValue(field)}
            <FormField title={formatTitle(field.name)}{field.isRequired ? "*" : ""}>
                <AmplifyFormElement {client} {field} {value} on:update={event => onUpdate(field, event.detail)}></AmplifyFormElement>
            </FormField>
        {/if}
    {/each}
</form>
