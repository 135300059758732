import { Field } from "@aws-amplify/core/dist/esm/singleton/API/types";
import { ReteynSchema } from "../schema";
import { ModelType } from "./ModelType";

export function parseTitleField(field: Field, object: ModelType): string {
  const titleString = object[field.name as keyof ModelType] as string;
  const parts = [titleString];
  const index = (object as ReteynSchema["Answer"]["type"]).index;
  if (typeof index === "number") {
    parts.unshift(`${index + 1}.`);
  }
  return parts.join(" ");
}
