<script lang="ts">
    import { type ModelIntrospectionSchema } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import { type ModelName, type ReteynSchema, organisationIdField } from "../schema";
    import { type V6Client } from "@aws-amplify/api-graphql";
    import { sortItems, parseTruthyResponse } from "../dao";
    import {listItems} from "./listItems";
    import LoadingScreen from "../reteyn-components/LoadingScreen.svelte";
    import List from "./List.svelte";
    import { ModelType } from "./ModelType";
    import { createEventDispatcher } from "svelte";
    import ModelItem from "./ModelItem.svelte";
    import EditorCard from "./EditorCard.svelte";
    import { formatTitle } from "./formatTitle";
    import { AuthorisationResolver } from "../organisation/index.js";
    
    export let organisationId: string | undefined;
    export let client: V6Client<ReteynSchema>;
    export let model: ModelName;
    export let modelSchema: ModelIntrospectionSchema;
    export let authResolver: AuthorisationResolver;

    const dispatch = createEventDispatcher<{select: ModelType}>();

    function list(modelName: ModelName): Promise<ModelType[]> {
        return listItems(client, {modelName, modelIntrospection: modelSchema, organisationId})
    }

    async function deleteListItem(item: ModelItem): Promise<void> {
        await parseTruthyResponse(client.models[model].delete(item));
        dataPromise = list(model);
    }

    let dataPromise: Promise<ModelType[]>;
    $: dataPromise = list(model);

</script>

{#await dataPromise}
    <LoadingScreen></LoadingScreen>
{:then data}
{@const items = sortItems(data)}
<EditorCard title={formatTitle(modelSchema.models[model].pluralName)}>
    <List
        {client}
        {model}
        {items}
        excludedTypes={[]}
        {modelSchema}
        on:select={(event) =>
            dispatch("select", event.detail)}
        on:remove={(event) =>
            deleteListItem(event.detail)}
        allowRemove={(item => authResolver.canEdit({organisationId: item[organisationIdField], modelName: model}))}
    ></List>
</EditorCard>
{/await}