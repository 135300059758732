<script lang="ts">
    import type { Field } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import { inputTypeMappings } from "./inputTypeMappings";
    import type { StringFieldType } from "./StringFieldType";
    import { createEventDispatcher } from "svelte";
    import { ElementValue } from "./ElementValue";
    import { toStringValue } from "./toStringValue";
    import { V6Client } from "@aws-amplify/api-graphql";
    import { ReteynSchema } from "../schema";

    const dispatch = createEventDispatcher<{update: HTMLInputElement | HTMLTextAreaElement}>()
    const inputClassMappings: Partial<Record<StringFieldType, string>> = {
        Boolean: "checkbox"
    };
    export let field: Field
    export let value: ElementValue | null;
    export let client: V6Client<ReteynSchema>;
        
    let type: string | undefined;
    let element: HTMLInputElement | HTMLTextAreaElement;
    let inputClass: string;

    $: type = inputTypeMappings[field.type as StringFieldType]
    $: inputClass = inputClassMappings[field.type as StringFieldType] || "input input-bordered w-full"

    let submitted: string;

    function onUpdate() {
        const newValue = toStringValue(element as HTMLInputElement);
        if (newValue !== submitted) {
            submitted = newValue;
            dispatch("update", element);
        }
    }

</script>

{#if field}
    {@const commonProps = {
        readonly: field.isReadOnly,
        name: field.name
    }}
    {@const required = field.isRequired}
    {#if field.type === "Boolean"}
        <input bind:this={element} {...commonProps} {type} class="{inputClass}" checked={value} on:change={() => onUpdate()}/>
    {:else if field.type === "String" && typeof value === "string" && value.length > 100}
        <textarea bind:this={element} {...commonProps} {required} {value} class="textarea textarea-bordered"  on:blur={() => onUpdate()}/>
    {:else if field.type.enum}
        {@const options = client.enums[field.type.enum].values()}
        <select {...commonProps} bind:this={element} {value} class="select select-bordered w-full" on:change={() => onUpdate()}>
            <option disabled selected>Pick one</option>
            {#each options as option}
                <option value={option}>{option}</option>
            {/each}
        </select>
    {:else}
        <input bind:this={element} {...commonProps} {required} {type} {value} class="{inputClass}"  on:blur={() => onUpdate()}/>
    {/if}
{/if}