<script lang="ts">
  import { Route as AppRoute, Config } from "../config";
  import FileSaver from "file-saver";
  import { Router, Route, navigate } from "svelte-navigator";
  import ProtectedRoute from "./ProtectedRoute.svelte";
  import Editor from "./Editor.svelte";
  import type { ModelIntrospectionSchema } from "@aws-amplify/core/internals/utils";
  import Create from "./Create.svelte";
  import CreateCourse from "./CreateCourse.svelte";
  import ModelList from "./ModelList.svelte";
  import { ModelName, type ReteynSchema } from "../schema";
  import Report from "./Report.svelte";
  import { generateClient } from "aws-amplify/data";
  import NavBar from "./NavBar.svelte";
  import { createQuizAndSendToCurrentUser } from "./createQuizAndSendToCurrentUser";
  import { formatTitle } from "./formatTitle";
  import { toEditRoute } from "./toEditRoute";
  import { AuthorisationResolver } from "../organisation";
  import { AuthSession, fetchAuthSession } from "@aws-amplify/core";
  import { sessionStore } from "./sessionStore";
  import { CourseIngester } from "../course-ingester";
    import UpdateReteyner from "./UpdateReteyner.svelte";

  export let config: Config;
  let ingester: CourseIngester;
  let modelSchema: ModelIntrospectionSchema;
  $: modelSchema = config.API?.GraphQL
    ?.modelIntrospection as ModelIntrospectionSchema;

  $: client = generateClient<ReteynSchema>();
  $: ingester = new CourseIngester(client);

  function parseModelName(modelTypeString: string): ModelName | undefined {
    return Object.values(ModelName).find((v) => v === modelTypeString);
  }

  async function downloadReteyner(reteynerId: string) {
    const reteyner = await ingester.findReteyner(reteynerId);
    const input = ingester.toCourseInput(reteyner);
    const resultsBlob = new Blob([JSON.stringify(input, null, 4)], {
      type: "text/plain;charset=utf-8",
    });
    const downloadFilename = input.title.replace(" ", "_") + ".json";
    FileSaver.saveAs(resultsBlob, downloadFilename);
  }

  function nextPathElement(request: { pathname: string; prefix: string }) {
    return (
      request.pathname.replace(request.prefix, "").split("/").shift() || ""
    );
  }
  function onTabChange(index: number) {
    const url = new URL(location.href);
    url.searchParams.set("index", index + "");
    window.history.pushState(null, "", url.toString());
  }

  function onCreated(request: {redirect: string, modelName: ModelName}) {
    if (request.modelName === ModelName.Organisation) {
      sessionStore.set(fetchAuthSession({forceRefresh: true}))
    }
    navigate(request.redirect);
  }

  function toCreateRoute(request: {
    model: ModelName;
    options: object;
  }): string {
    return `/${AppRoute.Create}/${request.model}?options=${JSON.stringify(request.options)}&redirect=${location.href.replace(location.origin, "")}`;
  }

  function resolveTabIndex(): number {
    const searchParams = new URLSearchParams(location.search);
    return parseInt(searchParams.get("index") || "0");
  }

  function toGroups(session: AuthSession | undefined): string[] {
    return (
      (session?.tokens?.idToken?.payload["cognito:groups"] as string[]) || []
    );
  }
</script>

<svelte:head>
  <style src="../reteyn-components/style.css"></style>
</svelte:head>
<Router>
  <ProtectedRoute let:session>
    {@const authResolver = new AuthorisationResolver(
      modelSchema,
      toGroups(session),
    )}
    <div class="flex flex-col full-height">
      <NavBar let:location>
        {@const editPrefix = `/${AppRoute.Edit}/`}
        {@const buttonOptions = { class: "btn btn-ghost" }}
        {#if location.pathname.startsWith(editPrefix)}
          {@const organisationId = nextPathElement({
            pathname: location.pathname,
            prefix: editPrefix,
          })}
          {@const orgPrefix = editPrefix + organisationId + "/"}
          {@const model = parseModelName(
            nextPathElement({
              pathname: location.pathname,
              prefix: orgPrefix,
            }),
          )}
          {#if model}
            {@const id = nextPathElement({
              pathname: location.pathname,
              prefix: orgPrefix + model + "/",
            })}
            {#if id}
              {#if model === ModelName.Question}
                <button
                  {...buttonOptions}
                  on:click={async () => {
                    if (organisationId) {
                      const test = await createQuizAndSendToCurrentUser(
                        client,
                        {
                          questionId: id,
                          studentDashboardOrigin: `https://${config.domains.recall}`,
                        },
                      );
                      navigate(
                        toEditRoute({
                          organisationId,
                          model: ModelName.Test,
                          id: test.id,
                        }),
                      );
                    }
                  }}
                >
                  Test
                </button>
              {/if}
              {#if model === ModelName.Reteyner}
                <button {...buttonOptions} on:click={() => navigate(`/${AppRoute.Update}/${id}`)}>Update</button>
                <button
                  {...buttonOptions}
                  on:click={() => navigate(`/${AppRoute.Result}/${id}`)}
                  >Report</button
                >
                <button {...buttonOptions} on:click={() => downloadReteyner(id)}>
                  Download
                </button>
              {/if}
            {:else if authResolver.canEdit( { modelName: model, organisationId }, )}
              <button
                {...buttonOptions}
                on:click={() =>
                  navigate(
                    toCreateRoute({
                      model,
                      options: { organisationId },
                    }),
                  )}
                >Create {formatTitle(modelSchema.models[model].name)}</button
              >
            {/if}
          {/if}
          {#if authResolver.canEdit( { modelName: ModelName.Reteyner, organisationId }, )}
            <button
              {...buttonOptions}
              on:click={() =>
                navigate(`/${AppRoute.Import}/${organisationId}/`)}
              >Import</button
            >
          {/if}
        {:else}
          {@const model = ModelName.Organisation}
          <button
            {...buttonOptions}
            on:click={() =>
              navigate(
                toCreateRoute({
                  model,
                  options: {},
                }),
              )}>Create {formatTitle(modelSchema.models[model].name)}</button
          >
        {/if}
      </NavBar>
      <div class="flex flex-col grow overflow-y-scroll">
        <Route path="/">
          {@const model = ModelName.Organisation}
          <ModelList
            {authResolver}
            {client}
            organisationId={undefined}
            {model}
            {modelSchema}
            on:select={(event) =>
              navigate(
                toEditRoute({
                  organisationId: event.detail.id,
                  model,
                  id: event.detail.id,
                }),
              )}
          />
        </Route>
        <Route path="/{AppRoute.Import}/:organisationId" let:params>
          <CreateCourse organisationId={params.organisationId} {ingester}/>
        </Route>
        <Route path="/{AppRoute.Update}/:id" let:params>
          <UpdateReteyner {ingester} reteynerId={params.id} on:update={({detail}) => navigate(toEditRoute({
            organisationId: detail.organisationId || "",
            id: detail.id,
            model: ModelName.Reteyner
          }))}/>
        </Route>
        <Route path="/{AppRoute.Result}/:id" let:params>
          <Report {client} reteynerId={params.id} />
        </Route>

        <Route path="/{AppRoute.Create}/:model" let:params>
          {@const model = parseModelName(params.model)}
          {#if model}
            {@const searchParams = new URLSearchParams(location.search)}
            {@const createParamsString = searchParams.get("options")}
            {@const createParams = createParamsString
              ? JSON.parse(createParamsString)
              : {}}
            {@const redirect = searchParams.get("redirect")}
            <Create
              {client}
              {model}
              {modelSchema}
              params={createParams}
              on:submit={() => onCreated({redirect: redirect || location.href, modelName: model})}
            >
              {#if redirect}
                <button class="btn" on:click={() => navigate(redirect)}
                  >Cancel</button
                >
              {/if}
            </Create>
          {/if}
        </Route>
        <Route path="{AppRoute.Edit}/:organisationId/*" let:params>
          {@const organisationId = params.organisationId}
          <Route path="/">
            {@const model = ModelName.Organisation}
            {@const tabIndex = resolveTabIndex()}
            <Editor
              {authResolver}
              {client}
              {model}
              id={organisationId}
              {modelSchema}
              {tabIndex}
              on:tab={(event) => onTabChange(event.detail)}
              on:select={(event) =>
                navigate(
                  toEditRoute({
                    organisationId,
                    model: event.detail.model,
                    id: event.detail.item.id,
                  }),
                )}
              on:create={(event) =>
                navigate(
                  toCreateRoute({
                    model: event.detail.model,
                    options: { ...event.detail.options, organisationId },
                  }),
                )}
            ></Editor>
          </Route>
          <Route path=":model/*" let:params>
            {@const model = parseModelName(params.model)}
            {#if model}
              <Route path="/">
                <ModelList
                  {authResolver}
                  {organisationId}
                  {client}
                  {model}
                  {modelSchema}
                  on:select={(event) =>
                    navigate(
                      toEditRoute({
                        organisationId,
                        model,
                        id: event.detail.id,
                      }),
                    )}
                />
              </Route>
              <Route path=":id" let:params>
                {@const id = params.id}
                {@const tabIndex = resolveTabIndex()}
                <Editor
                  {authResolver}
                  {client}
                  {model}
                  {id}
                  {modelSchema}
                  {tabIndex}
                  on:tab={(event) => onTabChange(event.detail)}
                  on:select={(event) =>
                    navigate(
                      toEditRoute({
                        organisationId,
                        model: event.detail.model,
                        id: event.detail.item.id,
                      }),
                    )}
                  on:create={(event) =>
                    navigate(
                      toCreateRoute({
                        model: event.detail.model,
                        options: { ...event.detail.options, organisationId },
                      }),
                    )}
                />
              </Route>
            {/if}
          </Route>
        </Route>
      </div>
    </div>
  </ProtectedRoute>
</Router>
