<script lang="ts">
    export let title: string;
</script>
<div class="card flex flex-row justify-center">
    <div class="card-body flex flex-col w-full max-w-screen-xl">
        <h2 class="card-title">{title}</h2>
        <div class="flex gap-y-3 flex-col overflow-y-scroll">
            <slot/>
        </div>
    </div>
</div>
