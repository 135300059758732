import {
  filter,
  Observable,
  UnaryFunction,
  pipe,
  OperatorFunction,
} from "rxjs";

export function filterNullish<T>(): UnaryFunction<
  Observable<T | null | undefined>,
  Observable<T>
> {
  return pipe(
    filter((x) => x != null) as OperatorFunction<T | null | undefined, T>,
  );
}
