export function sortItems<T>(items: T[]): T[] {
  if (
    items.every((item) => typeof (item as { index: number }).index === "number")
  ) {
    return items.sort(
      (item1, item2) =>
        ((item1 as { index: number }).index as number) -
        ((item2 as { index: number }).index as number),
    );
  }
  return items;
}
