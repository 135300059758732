<script lang="ts">
    import { V6Client } from "@aws-amplify/api-graphql";
    import type { ModelName, ReteynSchema } from "../schema";
    import type { ModelIntrospectionSchema } from "@aws-amplify/core/dist/esm/singleton/API/types";
    import { ModelType } from "./ModelType";
    import { toTitle } from "./toTitle";
    import Item from "./Item.svelte";
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher<{click: null}>();
    export let client: V6Client<ReteynSchema>;
    export let schema: ModelIntrospectionSchema;
    export let modelName: ModelName;
    export let object: ModelType;
    export let excludedTypes: ModelName[];

    let titlePromise: Promise<string>;
    type ValidityType = {correct: boolean};
    let validityObject: undefined | ValidityType;
    
    $: validityObject = typeof (object as ValidityType).correct === "boolean" ? object as ValidityType : undefined;
    $: titlePromise = toTitle(client, schema, modelName, object, excludedTypes);

</script>

{#await titlePromise}
    <Item title={""} on:click={() => dispatch("click")}>
        <span class="loading loading-ring loading-xs"></span>
    </Item>
{:then title}
    <Item {title} on:click={() => dispatch("click")}>
        {#if validityObject}
            <div class="badge badge-outline badge-{validityObject.correct ? "success" : "error"}">
                {validityObject.correct ? "correct" : "wrong"}
            </div>
        {/if}
        <slot />
    </Item>
{/await}
